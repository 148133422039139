/**
 * Profiles Grid
 */

import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getGatsbyImageData } from "gatsby-source-sanity"
import { sanityConfig } from "../../utils/sanity"

export function Profiles({ data }) {
  return (
    <div className="mb-0 space-y-4">
      {data.map((profile) => {
        const profileImage = profile.profile_image
          ? getGatsbyImageData(
              profile.profile_image.asset._id,
              { maxWidth: 500 },
              sanityConfig
            )
          : null

        return (
          <div className="grid grid-cols-6 gap-x-4 sm:gap-x-8 gap-y-4 pb-8 items-center">
            <div className="col-span-2 md:col-span-1">
              <GatsbyImage className="rounded" image={profileImage} />
            </div>

            <div className="col-span-4">
              <h2 className="text-lg sm:text-xl lg:text-2xl serif mb-2">
                {profile.name}
              </h2>
              {profile.place_of_residence && (
                <p className="text-base serif mb-3 text-gray-600">
                  {profile.place_of_residence}
                </p>
              )}
            </div>

            <div className="col-span-full">
              <p className="text-base leading-8">{profile.bio}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
